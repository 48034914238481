import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ChevronDown } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

const LanguageSwitcher = ({ languages, translations, currentLanguage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    languages.find((lang) => lang.code === currentLanguage) || languages[0]
  );

  useEffect(() => {
    const foundLang = languages.find((lang) => lang.code === currentLanguage);
    if (foundLang) {
      setSelectedLanguage(foundLang);
    } else {
      setSelectedLanguage(languages.find((lang) => lang.code === 'es'));
    }
  }, [currentLanguage, languages]);

  const constructHref = (language) => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const languagesCodes = languages.map((lang) => lang.code);

    const isHomePage =
      pathSegments.length === 0 ||
      (pathSegments.length === 1 && languagesCodes.includes(pathSegments[0]));

    if (isHomePage) {
      // Si estamos en la página de inicio
      return language.code === 'es' ? '/' : `/${language.code}/`;
    } else {
      // Para otras páginas, verificamos traducciones
      if (translations[language.code]) {
        return language.code === 'es'
          ? `/${translations['es']}/`
          : `/${language.code}/${translations[language.code]}/`;
      } else {
        // Si no existe traducción, retornamos null
        return null;
      }
    }
  };

  const generateHreflangLinks = () => {
    return languages
      .map((language) => {
        const href = constructHref(language);
        return href
          ? { hreflang: language.hreflang, href: `${baseUrl}${href}` }
          : null; // Excluimos URLs inválidas
      })
      .filter(Boolean); // Filtramos nulos
  };

  const baseUrl = process.env.REACT_APP_BASE_URL
    ? process.env.REACT_APP_BASE_URL.replace(/\/$/, '')
    : window.location.origin;

  const hreflangLinks = generateHreflangLinks();

  // Añadir x-default apuntando a la versión en español
  const spanishHref = constructHref(languages.find((lang) => lang.code === 'es'));
  if (spanishHref) {
    hreflangLinks.push({
      hreflang: 'x-default',
      href: `${baseUrl}${spanishHref}`,
    });
  }

  return (
    <>
      {/* Insertar las etiquetas hreflang en el head */}
      <Helmet>
        {hreflangLinks.map((link) => (
          <link
            key={link.hreflang}
            rel="alternate"
            hreflang={link.hreflang}
            href={link.href}
          />
        ))}
      </Helmet>

      <div className="dropdown language-switcher d-inline-block">
        <button
          className="dropdown-toggle btn btn-link text-decoration-none text-color-grey"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          aria-expanded={isOpen}
        >
          <img
            src={selectedLanguage.flag}
            alt={`${selectedLanguage.name} flag`}
            className="me-1"
            style={{ width: '20px', height: '15px' }}
          />
          <span className="language-switch" style={{ color: '#4a4a49' }}>
            {selectedLanguage.name}
          </span>
          <ChevronDown className="ms-1 text-white" size={16} />
        </button>

        <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
          {languages.map((language) => {
            const href = constructHref(language);
            return href ? (
              <Link
                key={language.code}
                to={href}
                className="dropdown-item d-flex align-items-center"
                onClick={() => setIsOpen(false)}
              >
                <img
                  src={language.flag}
                  alt={`${language.name} flag`}
                  className="shadow-sm me-2"
                  style={{ width: '20px', height: '15px' }}
                />
                <span>{language.name}</span>
              </Link>
            ) : null;
          })}
        </div>
      </div>
    </>
  );
};

LanguageSwitcher.propTypes = {
  languages: PropTypes.array.isRequired,
  translations: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

export default LanguageSwitcher;
