// src/components/Pages/Services.jsx
import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { getPageBySlug } from '../../helpers/Api';
import Header from '../Header';
import Footer from '../Footer';
import Section from '../Section';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import AboutSectionStyle2 from '../Section/AboutSection/AboutSectionStyle2';
import DepartmentSectionStyle4 from '../Section/DepartmentSection/DepartmentSectionStyle4';
import FormStyle1 from '../Form/FormStyle1';
import FormStyle2 from '../Form/FormStyle2';
import FormStyle3 from '../Form/FormStyle3';
import FormStyle4 from '../Form/FormStyle4';
import FormStyle5 from '../Form/FormStyle5';
import FormStyle6 from '../Form/FormStyle6';
import FormStyle7 from '../Form/FormStyle7';
import FormStyle8 from '../Form/FormStyle8';
import ContactMethods from '../ContactMethods/ContactMethods';

import Accordion from '../Accordion';
import ReactDOMServer from 'react-dom/server';
import parse from 'html-react-parser';
import languages from '../../config/languages';
import { TranslationContext } from '../../contexts/TranslationContext';

// Función para obtener la base URL
const getBaseUrl = () => 'https://dashboard.gestlifesurrogacy.com';

// Función para obtener una imagen de respaldo si la original no está disponible
const getFallbackImage = (type) => {
  switch (type) {
    case 'bg':
      return '/images/fallback-bg.webp';
    case 'img':
      return '/images/fallback-img.webp';
    default:
      return '/images/default.webp';
  }
};

// Procesar contenido HTML para actualizar rutas de imágenes y agregar lazy loading
const processHtmlContent = (html, baseUrl) => {
  if (typeof document === 'undefined') return html;

  const div = document.createElement('div');
  div.innerHTML = html;
  const images = div.getElementsByTagName('img');

  for (let img of images) {
    const src = img.getAttribute('src');
    if (src && src.startsWith('/uploads')) {
      img.setAttribute('src', baseUrl + src);
    }
    img.setAttribute('loading', 'lazy');
  }

  return div.innerHTML;
};

// Funciones para reemplazar shortcodes con componentes React
const replaceAboutSection = (html) => {
  const aboutSectionRegex = /--about-section title="([^"]+)" subTitle="([^"]+)" imgUrl="([^"]+)"--/g;
  return html.replace(aboutSectionRegex, (match, title, subTitle, imgUrl) => {
    const aboutSectionComponent = (
      <AboutSectionStyle2
        title={title}
        subTitle={subTitle}
        imgUrl={imgUrl}
      />
    );
    return ReactDOMServer.renderToString(aboutSectionComponent);
  });
};

const replaceDepartmentSection = (html) => {
  const departmentSectionRegex = /--department-section sectionTitle="([^"]+)" sectionTitleUp="([^"]+)" data='([^']+)'--/g;
  return html.replace(departmentSectionRegex, (match, sectionTitle, sectionTitleUp, data) => {
    const parsedData = JSON.parse(data);
    return `<div data-component="DepartmentSectionStyle4" data-props='${JSON.stringify({
      sectionTitle,
      sectionTitleUp,
      data: parsedData,
    })}'></div>`;
  });
};

const replaceFormStyle1 = (html) => {
  const formStyle1Regex = /--form-style-1--/g;
  return html.replace(formStyle1Regex, () => `<div data-component='FormStyle1'></div>`);
};

const replaceFormStyle2 = (html) => {
  const formStyle2Regex = /--form-style-2--/g;
  return html.replace(formStyle2Regex, () => `<div data-component='FormStyle2'></div>`);
};

const replaceFormStyle3 = (html) => {
  const formStyle3Regex = /--form-style-3--/g;
  return html.replace(formStyle3Regex, () => `<div data-component='FormStyle3'></div>`);
};

const replaceFormStyle4 = (html) => {
  const formStyle4Regex = /--form-style-4--/g;
  return html.replace(formStyle4Regex, () => `<div data-component='FormStyle4'></div>`);
};

const replaceFormStyle5 = (html) => {
  const formStyle5Regex = /--form-style-5--/g;
  return html.replace(formStyle5Regex, () => `<div data-component='FormStyle5'></div>`);
};

const replaceFormStyle6 = (html) => {
  const formStyle6Regex = /--form-style-6--/g;
  return html.replace(formStyle6Regex, () => `<div data-component='FormStyle6'></div>`);
};

const replaceFormStyle7 = (html) => {
  const formStyle7Regex = /--form-style-7--/g;
  return html.replace(formStyle7Regex, () => `<div data-component='FormStyle7'></div>`);
};

const replaceFormStyle8 = (html) => {
  const formStyle8Regex = /--form-style-8--/g;
  return html.replace(formStyle8Regex, () => `<div data-component='FormStyle8'></div>`);
};

const replaceAccordion = (html) => {
  const accordionRegex = /--accordion\[(\[[\s\S]*?\])\]accordion--/g;

  return html.replace(accordionRegex, (match, jsonData) => {
    try {
      const parsedData = JSON.parse(jsonData.trim()).map((item) => ({
        title: item.question,
        content: item.answer,
      }));
      const props = JSON.stringify({
        data: parsedData,
        variant: 'cs_accordians text-color-grey cs_style1',
      }).replace(/'/g, '&#39;');
      return `<div data-component='Accordion' data-props='${props}'></div>`;
    } catch (error) {
      console.error('Error parsing accordion JSON:', error);
      return `<div class="accordion-error">Error procesando el acordeón. Por favor, verifica el formato del JSON.</div>`;
    }
  });
};

const replaceShortcodesWithComponents = (html) => {
  html = replaceAboutSection(html);
  html = replaceDepartmentSection(html);
  html = replaceFormStyle1(html);
  html = replaceFormStyle2(html);
  html = replaceFormStyle3(html);
  html = replaceFormStyle4(html);
  html = replaceFormStyle5(html);
  html = replaceFormStyle6(html);
  html = replaceFormStyle7(html);
  html = replaceFormStyle8(html);
  html = replaceAccordion(html);
  return html;
};

const isExternalLink = (url) => {
  try {
    const linkDomain = new URL(url, 'https://dashboard.gestlifesurrogacy.com').hostname;
    // Verificamos que el dominio termine con "gestlifesurrogacy.com"
    // Si no termina con esto, consideramos el enlace como externo.
    return !linkDomain.endsWith('gestlifesurrogacy.com');
  } catch (e) {
    // Si ocurre algún error al parsear la URL, por precaución lo consideramos externo.
    return true;
  }
};

const renderComponents = (html) => {
  return parse(html, {
    replace: (domNode) => {
      // Si el nodo es un enlace <a>, verificamos si es externo
      if (domNode.name === 'a' && domNode.attribs && domNode.attribs.href) {
        const hrefValue = domNode.attribs.href;

        // Si es un enlace externo (no gestlifesurrogacy.com), agregamos rel="nofollow"
        if (isExternalLink(hrefValue)) {
          domNode.attribs.rel = 'nofollow';
          // Opcional: abrir en una nueva pestaña
          // domNode.attribs.target = '_blank';
        }
      }

      if (domNode.attribs && domNode.attribs['data-component']) {
        const Component = {
          DepartmentSectionStyle4,
          FormStyle1,
          FormStyle2,
          FormStyle3,
          FormStyle4,
          FormStyle5,
          AboutSectionStyle2,
          Accordion,
          FormStyle6,
          FormStyle7,
          FormStyle8
        }[domNode.attribs['data-component']];

        if (Component) {
          let props = {};
          if (domNode.attribs['data-props']) {
            try {
              props = JSON.parse(domNode.attribs['data-props']);
            } catch (error) {
              console.error(
                `Error parsing props for component ${domNode.attribs['data-component']}:`,
                error
              );
            }
          }

          return (
            <Section bottomMd={125} bottomLg={0} bottomXl={85}>
              <Component {...props} />
            </Section>
          );
        }
      }
    },
  });
};


const Services = () => {
  const { slug } = useParams();
  const [page, setPage] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const baseUrl = getBaseUrl();
  const location = useLocation();
  const navigate = useNavigate();
  const languageCode = location.pathname.split('/')[1];
  
  // Verificar si el primer segmento de la ruta es un código de idioma válido
  const language = languages[languageCode] ? languageCode : 'es';
  const { setTranslations } = useContext(TranslationContext);

  useEffect(() => {
    // Detecta si la pantalla es móvil
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const pageSlug = language === 'es' ? slug : `${language}/${slug}`;
        const data = await getPageBySlug(pageSlug);
        const contentWithComponents = replaceShortcodesWithComponents(
          processHtmlContent(data.content, baseUrl)
        );
        setPage({ ...data, content: contentWithComponents });

        const translationsMap = {};

        if (language === 'es') {
          translationsMap['es'] = slug;
          if (data.translations && Array.isArray(data.translations)) {
            data.translations.forEach((t) => {
              const slugWithoutLang = t.slug.startsWith(`${t.locale}/`)
                ? t.slug.slice(t.locale.length + 1)
                : t.slug;
              translationsMap[t.locale] = slugWithoutLang;
            });
          }
        } else {
          translationsMap[language] = slug;
          if (data.main_page && data.main_page.slug) {
            translationsMap['es'] = data.main_page.slug;
          }
          if (data.main_page && data.main_page.translations && Array.isArray(data.main_page.translations)) {
            data.main_page.translations.forEach((t) => {
              const slugWithoutLang = t.slug.startsWith(`${t.locale}/`)
                ? t.slug.slice(t.locale.length + 1)
                : t.slug;
              translationsMap[t.locale] = slugWithoutLang;
            });
          }
          if (data.translations && Array.isArray(data.translations)) {
            data.translations.forEach((t) => {
              const slugWithoutLang = t.slug.startsWith(`${t.locale}/`)
                ? t.slug.slice(t.locale.length + 1)
                : t.slug;
              if (!translationsMap[t.locale]) {
                translationsMap[t.locale] = slugWithoutLang;
              }
            });
          }
        }

        setTranslations(translationsMap);
        //console.log('Translations in context:', translationsMap);

      } catch (error) {
        console.error('Error fetching page', error);
        navigate('/');
      }
    };
    fetchPage();
  }, [slug, baseUrl, language, setTranslations, navigate]);

  // Nuevo useEffect para manejar la visibilidad de los divs al hacer clic en los botones
  useEffect(() => {
    if (!page) return;

    const handleFormVisibility = () => {
      const buttons = document.querySelectorAll(".form1, .form2, .form3");
      if (buttons.length === 0) {
        //console.warn("No se encontraron botones con las clases .form1, .form2, .form3");
        return;
      }

      const divs = {
        form1: document.getElementById("form1"),
        form2: document.getElementById("form2"),
        form3: document.getElementById("form3"),
      };

      Object.keys(divs).forEach((key) => {
        if (!divs[key]) {
          //console.warn(`No se encontró el div con ID #${key}`);
        }
      });

      Object.values(divs).forEach((div) => {
        if (div) {
          div.style.display = "none";
        }
      });

      const handleClick = (event) => {
        // Buscar la clase form1, form2 o form3 entre las clases del botón
        const classList = Array.from(event.currentTarget.classList);
        const formClass = classList.find(cls => ["form1", "form2", "form3"].includes(cls));

        if (!formClass) {
         //console.warn("No se encontró una clase válida (form1, form2 o form3) en el botón clicado");
          return;
        }

        //console.log(`Botón clicado: ${formClass}`);

        Object.values(divs).forEach((div) => {
          if (div) {
            div.style.display = "none";
          }
        });

        if (divs[formClass]) {
          divs[formClass].style.display = "inline";
          //console.log(`Mostrando: #${formClass}`);
        } else {
          //onsole.warn(`No se encontró un div asociado a ${formClass}`);
        }
      };

      buttons.forEach((button) => {
        button.addEventListener("click", handleClick);
      });

      return () => {
        buttons.forEach((button) => {
          button.removeEventListener("click", handleClick);
        });
      };
    };

    const cleanup = handleFormVisibility();
    return cleanup;
  }, [page]);

  if (!page) return null;

  const { title, extras } = page;
  const metaTitle = (extras && extras.meta_title) || title;
  const metaDescription = (extras && extras.meta_description) || '';
  const bgUrl = extras.bg_url ? `${baseUrl}/${extras.bg_url}` : getFallbackImage('bg');
  const imgUrl = extras.img_url ? `${baseUrl}/${extras.img_url}` : getFallbackImage('img');

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="preconnect" href={baseUrl} crossOrigin="true" />
        <link rel="preload" as="image" href={`${baseUrl}/images/logotipo_blanco.svg`} />
        {extras.img_url && <link rel="preload" as="image" href={imgUrl} />}
      </Helmet>

      <Header logoSrc="/images/logotipo_blanco.svg" />

      {!isMobile && (
        <BannerSectionStyle5
          bgColor="linear-gradient(40deg, #EE6FBB, #7BADD3)"
          imgUrl={imgUrl}
          title={title}
          subTitle={extras.subtitle}
        />
      )}

      {isMobile && (
        <div
          style={{
            background: 'linear-gradient(40deg, #EE6FBB, #7BADD3)',
            padding: '100px 0px',
            textAlign: 'center',
            borderBottomLeftRadius: '50px',
            borderBottomRightRadius: '50px',
          }}
        >
          <h1
            style={{
              color: '#ffffff',
              fontSize: '30px',
              marginBottom: '10px',
              marginTop: '30px',
              padding: '0px 20px',
            }}
          >
            {title}
          </h1>
          <p style={{ marginBottom: '10px', padding: '0px 20px' }}>{extras.subtitle}</p>
        </div>
      )}

      <Section className="section-loaded" topMd={60} bottomMd={200}>
        <div className="container container-services">
          <style>{extras.content_css}</style>
          {renderComponents(page.content)}
        </div>
      </Section>
      <ContactMethods />
      <Footer />
    </>
  );
};

export default Services;
