// src/index.js

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import './sass/index.scss';
import './i18n';
import { TranslationProvider } from './contexts/TranslationContext';

const AppWrapper = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Actualizar la expresión regular para incluir 'en-au' y eliminar 'au'
  const languageRegex = /^\/(es|en|en-au|pt|fr|it|de)(\/|$)/;
  const currentPath = window.location.pathname;

  // Redirigir de '/au' a '/en-au'
  useEffect(() => {
    const auRegex = /^\/au(\/|$)/;
    if (auRegex.test(currentPath)) {
      const newPath = currentPath.replace(/^\/au/, '/en-au');
      navigate(newPath, { replace: true });
    }
  }, [currentPath, navigate]);

  // Verificar si el idioma está en la URL
  const isLanguageInURL = languageRegex.test(currentPath);
  
  // Extraer el idioma actual o por defecto 'es'
  const match = currentPath.match(languageRegex);
  const currentLanguage = isLanguageInURL 
    ? match[1]
    : 'es'; // Por defecto, español

  // Obtener el GTM ID desde las traducciones
  const gtmId = t('gtm_id');

  useEffect(() => {
    if (!gtmId) {
      console.error('gtm_id no está definido en las traducciones.');
      return;
    }

    const script = document.createElement('script');
    script.async = true;
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [gtmId]);

  useEffect(() => {
    if (!gtmId) return;

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.insertBefore(noscript, document.body.firstChild);

    return () => {
      document.body.removeChild(noscript);
    };
  }, [gtmId]);

  // Obtener el dominio base desde la variable de entorno
  const baseUrl = process.env.REACT_APP_BASE_URL 
    ? process.env.REACT_APP_BASE_URL.replace(/\/$/, '') 
    : window.location.origin;

  // Configurar el atributo lang del elemento html
  useEffect(() => {
    document.documentElement.lang = currentLanguage;
  }, [currentLanguage]);

  return (
    <App />
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <TranslationProvider>
          <AppWrapper />
        </TranslationProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
