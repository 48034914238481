// src/components/Pages/Blog.jsx

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async'; // Importa Helmet para manejar el SEO
import Section from '../Section';
import BlogSectionStyle2 from '../Section/BlogSection/BlogSectionStyle2';
import Breadcrumb from '../Breadcrumb';
import { pageTitle } from '../../helpers/PageTitle';
import AppointmentSection from '../Section/AppointmentSection';
import { getBlogArticles } from '../../helpers/GetBlogArticle';
import Pagination from '../Pagination';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import languages from '../../config/languages';

export default function Blog() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // Obtener el código de idioma desde la URL
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const languageCode = pathSegments[1];
  const isLanguageValid = languages.hasOwnProperty(languageCode);
  const language = isLanguageValid ? languageCode : 'es';

  // Cambiar el idioma en i18n si es necesario
  useEffect(() => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  // Redirigir si el código de idioma en la URL no es válido
  useEffect(() => {
    if (!isLanguageValid && languageCode !== '') {
      navigate('/blog/');
    }
  }, [isLanguageValid, languageCode, navigate]);

  // Configurar el título de la página
  pageTitle(t('blog_title'));

  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        // Obtener los artículos del blog pasando el parámetro de idioma y la página actual
        const data = await getBlogArticles(currentPage, language);
        setArticles(data.data);
        setTotalPages(data.last_page);
        setIsLoading(false);

        if (window.snapSaveState) {
          window.snapSaveState();
        }
      } catch (error) {
        console.error('Error fetching blog articles', error);
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, [currentPage, language]);

  const blogData = articles.map((article) => ({
    title: article.title,
    thumbUrl: article.image.startsWith('http')
      ? article.image
      : `https://dashboard.gestlifesurrogacy.com/${article.image}`,
    date: new Date(article.date).toLocaleDateString(
      i18n.language === 'es'
        ? 'es-ES'
        : i18n.language === 'fr'
        ? 'fr-FR'
        : i18n.language === 'pt'
        ? 'pt-PT'
        : i18n.language === 'it'
        ? 'it-IT'
        : i18n.language === 'de'
        ? 'de-DE'
        : i18n.language === 'au'
        ? 'en-AU'
        : 'en-US',
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
    ),
    btnText: t('learn_more'),
    href: `/${language !== 'es' ? `${language}/` : ''}blog/${article.slug}/`,
    socialShare: true,
  }));

  // Generar la descripción meta dinámica
  const metaDescription = currentPage > 1
    ? `${t('meta_description_blog')} ${t('page')} ${currentPage}`
    : t('meta_description_blog');

  // Generar el título dinámico
  const pageTitleText = currentPage > 1
    ? `${t('blog_title')} - ${t('page')} ${currentPage}`
    : t('blog_title');

  return (
    <>
      {/* Helmet para manejar el SEO dinámicamente */}
      <Helmet>
        <title>{pageTitleText}</title>
        <meta name="description" content={metaDescription} />
    
      </Helmet>

      {/* Sección de encabezado con fondo degradado */}
      <Section
        style={{
          background: 'linear-gradient(40deg, #EE6FBB, #7BADD3)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '50px 0',
        }}
      />

      {/* Breadcrumb para la navegación */}
      <Section topMd={170} bottomMd={96} bottomLg={70}>
        <Breadcrumb title={t('blog_title')} />
      </Section>

      {/* Sección principal del blog */}
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        {isLoading ? (
          <p>{t('loading_articles')}</p>
        ) : (
          <BlogSectionStyle2 data={blogData} />
        )}
      </Section>

      {/* Paginación */}
      <Section bottomMd={50} bottomLg={50} bottomXl={50}>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </Section>
    </>
  );
}
