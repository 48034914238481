// FormStyle2.jsx Formulario de Colaboradores y Franquicias
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';

// Función para obtener los parámetros UTM de la URL
const getUTMParameters = () => {
  const params = new URLSearchParams(window.location.search);
  return {
    utm_source: params.get('utm_source') || '',
    utm_medium: params.get('utm_medium') || '',
    utm_campaign: params.get('utm_campaign') || '',
    utm_term: params.get('utm_term') || '',
    utm_content: params.get('utm_content') || '',
  };
};

// Función para obtener la dirección IP del usuario
const fetchUserIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip || '';
  } catch (error) {
    console.error('Error obteniendo la IP:', error);
    return '';
  }
};

const FormStyle2 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate(); // Inicializa el hook de navegación

  // Estado para los campos ocultos adicionales
  const [additionalFields, setAdditionalFields] = useState({
    idioma: i18n.language || 'es', // Idioma actual
    urlOrigen: document.referrer || window.location.href, // URL de origen
    ipUsuario: '', // Se llenará posteriormente
    ...getUTMParameters(), // Parámetros UTM
  });

  // Definir los valores de los campos ocultos básicos
  const hiddenFields = {
    option: 'INSERT_DATA',
    form_type: 'agent_collaborator',
    form_name_id: 'tu_form_name_id', // Reemplaza con el valor real
    campaign_origin_id: '',
    origin_id: '8',
    lead_type_id: '3',
    market_id: 'tu_marketType', // Reemplaza con el valor real
  };

  const [formData, setFormData] = useState({
    tipoForm: 'colaboradoresFranquicias',
    nombreForm: '',
    apellidosForm: '',
    telefonoForm: '',
    emailForm: '',
    paisForm: '',
    provinciaForm: '',
    ciudadForm: '',
    dirForm: '',
    dir2Form: '',
    cpForm: '',
    dias: [],
    horas: [],
    estudiosForm: '',
    profForm: '',
    idiomasForm: '',
    colabForm: [],
    observacionesForm: '',
    datosForm: false,
    ...hiddenFields, // Incluir los campos ocultos básicos
    ...additionalFields, // Incluir los campos ocultos adicionales
  });

  // Obtener la IP del usuario al montar el componente
  useEffect(() => {
    const getIP = async () => {
      const ip = await fetchUserIP();
      setAdditionalFields((prev) => ({ ...prev, ipUsuario: ip }));
      setFormData((prev) => ({ ...prev, ipUsuario: ip }));
    };
    getIP();
  }, []);

  // Actualizar el idioma si cambia
  useEffect(() => {
    setAdditionalFields((prev) => ({ ...prev, idioma: i18n.language }));
    setFormData((prev) => ({ ...prev, idioma: i18n.language }));
  }, [i18n.language]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      if (name === 'dias[]' || name === 'horas[]') {
        const arrayName = name.replace('[]', '');
        setFormData((prevData) => ({
          ...prevData,
          [arrayName]: checked
            ? [...prevData[arrayName], value]
            : prevData[arrayName].filter((item) => item !== value),
        }));
      } else if (name === 'colabForm') {
        // Manejo para múltiples opciones de colaboración
        setFormData((prevData) => ({
          ...prevData,
          colabForm: checked
            ? [...prevData.colabForm, value]
            : prevData.colabForm.filter((item) => item !== value),
        }));
      } else {
        setFormData((prevData) => ({ ...prevData, [name]: checked }));
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      telefonoForm: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construir los datos para enviar en formato correcto
    const data = new URLSearchParams();

    // Añadir todos los campos del formulario, incluidos los ocultos
    for (const key in formData) {
      // Si el campo es un array, se añade múltiples veces
      if (Array.isArray(formData[key])) {
        formData[key].forEach((item) => data.append(key, item));
      } else {
        data.append(key, formData[key]);
      }
    }

    // Enviar los datos al backend PHP
    try {
      const response = await fetch('https://www.gestlifesurrogacy.com/formularios/formStyle2.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data.toString(),
      });

      if (response.ok) {
        // Manejar la respuesta exitosa
        console.log('Formulario enviado con éxito');
         // Redirigir a la página /gracias/
         navigate(t('navigation_redirect'));
        // Opcional: Resetear el formulario o mostrar un mensaje al usuario
        setFormData({
          tipoForm: 'colaboradoresFranquicias',
          nombreForm: '',
          apellidosForm: '',
          telefonoForm: '',
          emailForm: '',
          paisForm: '',
          provinciaForm: '',
          ciudadForm: '',
          dirForm: '',
          dir2Form: '',
          cpForm: '',
          dias: [],
          horas: [],
          estudiosForm: '',
          profForm: '',
          idiomasForm: '',
          colabForm: [],
          observacionesForm: '',
          datosForm: false,
          ...hiddenFields, // Reiniciar los campos ocultos básicos
          ...additionalFields, // Reiniciar los campos ocultos adicionales
        });
      } else {
        // Manejar el error en la respuesta
        console.error('Error al enviar el formulario');
        // Opcional: Guardar los datos en el servidor o notificar al usuario
      }
    } catch (error) {
      console.error('Error de red:', error);
      // Opcional: Implementar una lógica de respaldo aquí
    }
  };

  return (
    <div className="form-style-1-container form-body">
      <form onSubmit={handleSubmit} className="form-style-1">
        {/* Campos ocultos básicos */}
        {Object.keys(hiddenFields).map((key) => (
          <input key={key} type="hidden" name={key} value={hiddenFields[key]} />
        ))}

        {/* Campos ocultos adicionales */}
        {Object.keys(additionalFields).map((key) => (
          <input key={key} type="hidden" name={key} value={additionalFields[key]} />
        ))}

        <div className="row ">
          <div className="col-12 col-md-6 ">
            <label htmlFor="nombreForm">
              <b>{t('nombre')}</b>
            </label>
            <input
              type="text"
              className="form-control"
              required
              name="nombreForm"
              id="nombreForm"
              value={formData.nombreForm}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor="apellidosForm">
              <b>{t('Apellidos')}</b>
            </label>
            <input
              type="text"
              className="form-control"
              required
              name="apellidosForm"
              id="apellidosForm"
              value={formData.apellidosForm}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <label htmlFor="telefonoForm">
              <b>{t('telefono')}</b>
            </label>
            <PhoneInput
              country={'es'}
              value={formData.telefonoForm}
              onChange={handlePhoneChange}
              inputProps={{
                name: 'telefonoForm',
                required: true,
                className: 'form-control',
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor="emailForm">
              <b>{t('email')}</b>
            </label>
            <input
              type="email"
              className="form-control"
              required
              name="emailForm"
              id="emailForm"
              value={formData.emailForm}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Se pueden añadir más campos de ser necesario */}

        <div className="row">
          <div className="col-12">
            <label>
              <b>{t('diasPreferidos')}</b>
            </label>
            <br />
            <small>{t('eligeTantasOpciones')}</small>
            <div>
              {['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'].map((day) => (
                <div key={day} className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`day-${day}`}
                    name="dias[]"
                    value={day}
                    checked={formData.dias.includes(day)}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor={`day-${day}`}>
                    {t(day.toLowerCase())}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Se pueden añadir más secciones de ser necesario */}

        {/* Franjas Horarias */}
        <div className="row">
          <div className="col-12">
            <label>
              <b>{t('franjaHoraria')}</b>
            </label>
            <div>
              {['9:00 a 12:00', '12:00 a 15:00', '15:00 a 18:00', '18:00 a 21:00'].map((hora) => (
                <div key={hora} className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`hora-${hora}`}
                    name="horas[]"
                    value={hora}
                    checked={formData.horas.includes(hora)}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor={`hora-${hora}`}>
                    {hora}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Estudios */}
        <div className="row">
          <div className="col-12">
            <label htmlFor="estudiosForm">
              <b>{t('estudios')}</b>
            </label>
            <textarea
              className="form-control"
              name="estudiosForm"
              id="estudiosForm"
              value={formData.estudiosForm}
              onChange={handleChange}
              rows="3"
              required
            />
          </div>
        </div>

        {/* Experiencia Profesional */}
        <div className="row">
          <div className="col-12">
            <label htmlFor="profForm">
              <b>{t('experienciaProfesional')}</b>
            </label>
            <textarea
              className="form-control"
              name="profForm"
              id="profForm"
              value={formData.profForm}
              onChange={handleChange}
              rows="3"
              required
            />
          </div>
        </div>

        {/* Idiomas */}
        <div className="row">
          <div className="col-12">
            <label htmlFor="idiomasForm">
              <b>{t('idiomas')}</b>
            </label>
            <textarea
              className="form-control"
              name="idiomasForm"
              id="idiomasForm"
              value={formData.idiomasForm}
              onChange={handleChange}
              rows="3"
              required
            />
          </div>
        </div>

        {/* Tipo de colaboración */}
        <div className="row">
          <div className="col-12">
            <label>
              <b>{t('tipoColaboracion')}</b>
            </label>
            <div>
              {['Colaborador', 'Agente', 'Franquicia', 'Otro'].map((tipo) => (
                <div key={tipo} className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`colab-${tipo}`}
                    name="colabForm"
                    value={tipo}
                    checked={formData.colabForm.includes(tipo)}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor={`colab-${tipo}`}>
                    {t(tipo.toLowerCase())}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Observaciones */}
        <div className="row">
          <div className="col-12">
            <label htmlFor="observacionesForm">
              <b>{t('observaciones')}</b>
            </label>
            <textarea
              className="form-control"
              name="observacionesForm"
              id="observacionesForm"
              value={formData.observacionesForm}
              onChange={handleChange}
              rows="3"
            />
          </div>
        </div>

        {/* Aceptación de los datos personales */}
        <div className="row">
          <div className="col-12">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="datosForm"
                name="datosForm"
                checked={formData.datosForm}
                onChange={handleChange}
                required
              />
              <label className="form-check-label" htmlFor="datosForm">
                {t('autorizoDatos')}
              </label>
            </div>
          </div>
        </div>

        {/* Botón de Envío */}
        <div className="row">
          <div className="col-12 col-md-4 offset-md-4 text-center">
            <button
              type="submit"
              className="btn btn-custom w-100 text-uppercase"
              style={{ backgroundColor: '#DA00C0', border: '0' }}
            >
              {t('enviar')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormStyle2;
