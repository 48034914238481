// FormStyle4.jsx Formulario de Embajadores
import React, { useState, useEffect } from 'react'; // Note: added useEffect
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';

// Function to get UTM parameters from the URL
const getUTMParameters = () => {
  const params = new URLSearchParams(window.location.search);
  return {
    utm_source: params.get('utm_source') || '',
    utm_medium: params.get('utm_medium') || '',
    utm_campaign: params.get('utm_campaign') || '',
    utm_term: params.get('utm_term') || '',
    utm_content: params.get('utm_content') || '',
  };
};

// Function to fetch user's IP address
const fetchUserIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip || '';
  } catch (error) {
    console.error('Error obtaining IP address:', error);
    return '';
  }
};

// Formulario para Embajadores de Gestlife
const FormStyle4 = () => {
  const { t, i18n } = useTranslation();
   const navigate = useNavigate(); // Inicializa el hook de navegación

  const [formData, setFormData] = useState({
    nombreForm: '',
    apellidosForm: '',
    sexoForm: '',
    fechaNacimientoForm: '',
    edadForm: '',
    direccionForm: '',
    poblacionForm: '',
    provinciaForm: '',
    paisForm: '',
    telefonoForm: '',
    emailForm: '',
    redesSociales: {
      facebook: '',
      instagram: '',
      tiktok: '',
    },
    motivoForm: '',
    descripcionForm: '',
    zonaForm: '',
    tiempoForm: '',
    tipoColaboracionForm: '',
    estudiosForm: '',
    idiomasForm: '',
    experienciaForm: '',
    cvFile: null,
    datosForm: false,
    // Additional fields
    idioma: i18n.language || '',
    urlOrigen: document.referrer || window.location.href,
    ipUsuario: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: '',
  });

  useEffect(() => {
    // Fetch IP address and UTM parameters on component mount
    const fetchData = async () => {
      const ip = await fetchUserIP();
      const utmParams = getUTMParameters();

      setFormData(prevData => ({
        ...prevData,
        ipUsuario: ip,
        ...utmParams,
      }));
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Update language if it changes
    setFormData(prevData => ({
      ...prevData,
      idioma: i18n.language || '',
    }));
  }, [i18n.language]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData(prevData => ({ ...prevData, [name]: checked }));
    } else if (name.startsWith('redesSociales.')) {
      const socialKey = name.split('.')[1];
      setFormData(prevData => ({
        ...prevData,
        redesSociales: {
          ...prevData.redesSociales,
          [socialKey]: value,
        },
      }));
    } else {
      setFormData(prevData => ({ ...prevData, [name]: value }));
    }
  };

  const handlePhoneChange = (value) => {
    setFormData(prevData => ({ ...prevData, telefonoForm: value }));
  };

  const handleFileChange = (e) => {
    setFormData(prevData => ({ ...prevData, cvFile: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Build form data to send
    const data = new FormData();

    // Add all fields to form data
    for (const key in formData) {
      if (key === 'cvFile') {
        if (formData.cvFile) {
          data.append(key, formData.cvFile);
        }
      } else if (key === 'redesSociales') {
        for (const socialKey in formData.redesSociales) {
          data.append(`redesSociales[${socialKey}]`, formData.redesSociales[socialKey]);
        }
      } else {
        data.append(key, formData[key]);
      }
    }

    // Send data to backend
    try {
      const response = await fetch('https://www.gestlifesurrogacy.com/formularios/formStyle4.php', {
        method: 'POST',
        body: data,
      });

      if (response.ok) {
        // Handle success
        console.log('Formulario enviado con éxito');
        // Redirigir a la página /gracias/
        navigate(t('navigation_redirect'));
        // Optionally reset form
      } else {
        console.error('Error al enviar el formulario');
      }
    } catch (error) {
      console.error('Error de red:', error);
    }
  };

  return (
    <div className="form-style-2-container form-body">
      <form onSubmit={handleSubmit} className="form-style-1" encType="multipart/form-data">
        {/* Hidden fields */}
        <input type="hidden" name="idioma" value={formData.idioma} />
        <input type="hidden" name="urlOrigen" value={formData.urlOrigen} />
        <input type="hidden" name="ipUsuario" value={formData.ipUsuario} />
        <input type="hidden" name="utm_source" value={formData.utm_source} />
        <input type="hidden" name="utm_medium" value={formData.utm_medium} />
        <input type="hidden" name="utm_campaign" value={formData.utm_campaign} />
        <input type="hidden" name="utm_term" value={formData.utm_term} />
        <input type="hidden" name="utm_content" value={formData.utm_content} />

        {/* Encabezado del formulario */}
        <div className="">
          <h3>{t('quieres')}</h3>
          <p>{t('ayudamos')}</p>
        </div>

        {/* Datos personales */}
        <div className="row">
          <div className="col-12 col-md-6">
            <label htmlFor="nombreForm"><b>{t('nombre')}</b></label>
            <input
              type="text"
              className="form-control"
              required
              name="nombreForm"
              value={formData.nombreForm}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor="apellidosForm"><b>{t('Apellidos')}</b></label>
            <input
              type="text"
              className="form-control"
              required
              name="apellidosForm"
              value={formData.apellidosForm}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Sexo y fecha de nacimiento */}
        <div className="row">
          <div className="col-12 col-md-6">
            <label htmlFor="sexoForm"><b>{t('sexo')}</b></label>
            <select
              className="form-control"
              name="sexoForm"
              value={formData.sexoForm}
              onChange={handleChange}
              required
            >
              <option value="">{t('seleccionaSexo')}</option>
              <option value="masculino">{t('masculino')}</option>
              <option value="femenino">{t('femenino')}</option>
            </select>
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor="fechaNacimientoForm"><b>{t('fechaNacimiento')}</b></label>
            <input
              type="date"
              className="form-control"
              name="fechaNacimientoForm"
              value={formData.fechaNacimientoForm}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Dirección, Población, Provincia */}
        <div className="row">
          <div className="col-12">
            <label htmlFor="direccionForm"><b>{t('direccion')}</b></label>
            <input
              type="text"
              className="form-control"
              name="direccionForm"
              value={formData.direccionForm}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <label htmlFor="poblacionForm"><b>{t('poblacion')}</b></label>
            <input
              type="text"
              className="form-control"
              name="poblacionForm"
              value={formData.poblacionForm}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-12 col-md-4">
            <label htmlFor="provinciaForm"><b>{t('provincia')}</b></label>
            <input
              type="text"
              className="form-control"
              name="provinciaForm"
              value={formData.provinciaForm}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-12 col-md-4">
            <label htmlFor="paisForm"><b>{t('pais')}</b></label>
            <input
              type="text"
              className="form-control"
              name="paisForm"
              value={formData.paisForm}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Teléfono y Email */}
        <div className="row">
          <div className="col-12 col-md-6">
            <label htmlFor="telefonoForm"><b>{t('telefono')}</b></label>
            <PhoneInput
              country={'es'}
              value={formData.telefonoForm}
              onChange={handlePhoneChange}
              inputProps={{
                name: 'telefonoForm',
                required: true,
                className: 'form-control'
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <label htmlFor="emailForm"><b>{t('email')}</b></label>
            <input
              type="email"
              className="form-control"
              name="emailForm"
              value={formData.emailForm}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Redes Sociales */}
        <div className="row">
          <div className="col-12 col-md-4">
            <label htmlFor="facebook"><b>{t('facebook')}</b></label>
            <input
              type="text"
              className="form-control"
              name="redesSociales.facebook"
              value={formData.redesSociales.facebook}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-4">
            <label htmlFor="instagram"><b>{t('instagram')}</b></label>
            <input
              type="text"
              className="form-control"
              name="redesSociales.instagram"
              value={formData.redesSociales.instagram}
              onChange={handleChange}
            />
          </div>
          <div className="col-12 col-md-4">
            <label htmlFor="tiktok"><b>{t('tiktok')}</b></label>
            <input
              type="text"
              className="form-control"
              name="redesSociales.tiktok"
              value={formData.redesSociales.tiktok}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Motivo de ser embajador */}
        <div className="row">
          <div className="col-12">
            <label htmlFor="motivoForm"><b>{t('motivoEmbajador')}</b></label>
            <textarea
              className="form-control"
              name="motivoForm"
              value={formData.motivoForm}
              onChange={handleChange}
              rows="3"
              required
            />
          </div>
        </div>

        {/* Descripción personal */}
        <div className="row">
          <div className="col-12">
            <label htmlFor="descripcionForm"><b>{t('quienEres')}</b></label>
            <textarea
              className="form-control"
              name="descripcionForm"
              value={formData.descripcionForm}
              onChange={handleChange}
              rows="3"
              required
            />
          </div>
        </div>

        {/* Zona de trabajo */}
        <div className="row">
          <div className="col-12">
            <label htmlFor="zonaForm"><b>{t('zonaEmbajador')}</b></label>
            <select
              className="form-control"
              name="zonaForm"
              value={formData.zonaForm}
              onChange={handleChange}
              required
            >
              <option value="">{t('seleccionaZona')}</option>
              <option value="ciudad">{t('soloCiudad')}</option>
              <option value="provincia">{t('provincia')}</option>
              <option value="pais">{t('pais')}</option>
            </select>
          </div>
        </div>

        {/* Tiempo disponible */}
        <div className="row">
          <div className="col-12">
            <label htmlFor="tiempoForm"><b>{t('tiempoEmbajador')}</b></label>
            <select
              className="form-control"
              name="tiempoForm"
              value={formData.tiempoForm}
              onChange={handleChange}
              required
            >
              <option value="">{t('seleccionaTiempo')}</option>
              <option value="finesDeSemana">{t('finesDeSemana')}</option>
              <option value="4a8Horas">{t('4a8Horas')}</option>
              <option value="9a14Horas">{t('9a14Horas')}</option>
              <option value="15a20Horas">{t('15a20Horas')}</option>
              <option value="masDe20Horas">{t('masDe20Horas')}</option>
            </select>
          </div>
        </div>

        {/* Tipo de colaboración */}
        <div className="row">
          <div className="col-12">
            <label htmlFor="tipoColaboracionForm"><b>{t('tipoColaboracion')}</b></label>
            <select
              className="form-control"
              name="tipoColaboracionForm"
              value={formData.tipoColaboracionForm}
              onChange={handleChange}
              required
            >
              <option value="">{t('seleccionaColaboracion')}</option>
              <option value="asesorExterno">{t('asesorExterno')}</option>
              <option value="empleadoParcial">{t('empleadoParcial')}</option>
              <option value="empleadoCompleto">{t('empleadoCompleto')}</option>
            </select>
          </div>
        </div>

        {/* CV */}
        <div className="row">
          <div className="col-12">
            <label htmlFor="cvFile"><b>{t('cvArchivo')}</b></label>
            <input
              type="file"
              className="form-control"
              name="cvFile"
              onChange={handleFileChange}
              required
            />
          </div>
        </div>

        {/* Aceptación de datos */}
        <div className="row">
          <div className="col-12">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="datosForm"
                name="datosForm"
                checked={formData.datosForm}
                onChange={handleChange}
                required
              />
              <label className="form-check-label" htmlFor="datosForm">
                {t('autorizoDatos')}
              </label>
            </div>
          </div>
        </div>

        {/* Botón de Enviar */}
        <div className="row">
          <div className="col-12 col-md-4 offset-md-4 text-center">
            <button
              type="submit"
              className="btn btn-custom w-100 text-uppercase"
              style={{ backgroundColor: '#DA00C0', border: '0' }}
            >
              {t('enviar')}
            </button>
          </div>
        </div>

      </form>
    </div>
  );
};

export default FormStyle4;
