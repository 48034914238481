//FormStlye6.jsx Formulario Libro
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const FormLibro = () => {
  const { t } = useTranslation();  // Hook para acceder a las traducciones

  // Definir los valores de los campos ocultos
  const hiddenFields = {
    option: 'INSERT_DATA',
    form_type: 'survey',
    form_name_id: 'survey_form_id',       // Reemplaza con el valor real
    campaign_origin_id: '',
    origin_id: '8',
    lead_type_id: '3',
    market_id: 'tu_marketType',            // Reemplaza con el valor real
  };

  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    telefono: '',
    politica: false,
    respuesta1: '', // Cualidades en la agencia
    respuesta2: '', // Importancia de ser un despacho de abogados
    respuesta3: '', // Importancia de acompañamiento en los viajes
    respuesta4: '', // Acompañamiento de abogado al consulado
    respuesta5: '', // Oficinas en el país de destino
    respuesta6: '', // Garantía de reinicio
    respuesta7: '', // Acceso al expediente
    respuesta8: '', // Informe semanal
    respuesta9: '', // Hablar con otros padres
    comoNosConociste: '',
    consultaWeb: '',
    paisPreferido: '',
    comentarioMejoras: '',
    deseaInformacion: '',
    ...hiddenFields,  // Incluir los campos ocultos en el estado
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      telefono: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construir los datos para enviar en formato correcto
    const data = new URLSearchParams();

    // Añadir todos los campos del formulario, incluidos los ocultos
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    // Enviar los datos al backend PHP
    try {
      const response = await fetch('https://cdn.gestlife.com/assets/functions/leadcontrol/form-execute.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data.toString(),
      });

      if (response.ok) {
        // Manejar la respuesta exitosa
        console.log('Formulario enviado con éxito');
        // Opcional: Resetear el formulario o mostrar un mensaje al usuario
        setFormData({
          nombre: '',
          email: '',
          telefono: '',
          politica: false,
          respuesta1: '',
          respuesta2: '',
          respuesta3: '',
          respuesta4: '',
          respuesta5: '',
          respuesta6: '',
          respuesta7: '',
          respuesta8: '',
          respuesta9: '',
          comoNosConociste: '',
          consultaWeb: '',
          paisPreferido: '',
          comentarioMejoras: '',
          deseaInformacion: '',
          ...hiddenFields,  // Reiniciar los campos ocultos
        });
      } else {
        // Manejar el error en la respuesta
        console.error('Error al enviar el formulario');
      }
    } catch (error) {
      console.error('Error de red:', error);
    }
  };

  return (
    <div className="form-survey-container">
      <form onSubmit={handleSubmit} className="form-survey">
        {/* Campos ocultos */}
        {Object.keys(hiddenFields).map((key) => (
          <input
            key={key}
            type="hidden"
            name={key}
            value={hiddenFields[key]}
          />
        ))}

        {/* Form Header */}
        <div className="form-header">
          <h3>{t('survey_title')}</h3>
          <p>{t('survey_intro')}</p>
        </div>

        {/* Form Body */}
        <div className="form-body">
          {/* Nombre Apellidos */}
          <div className="form-group">
            <input
              type="text"
              name="nombre"
              required
              value={formData.nombre}
              onChange={handleChange}
              className="form-control"
              placeholder={t('nombre')}
            />
          </div>

          {/* Correo Electrónico */}
          <div className="form-group">
            <input
              type="email"
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
              className="form-control"
              placeholder={t('email')}
            />
          </div>

          {/* Teléfono */}
          <div className="form-group phone-input">
            <PhoneInput
              country={'es'}  // País predeterminado
              value={formData.telefono}
              onChange={handlePhoneChange}
              inputClass="form-control telefono-input"
              enableSearch={true}
              searchPlaceholder={t('telefono')}
            />
          </div>

          {/* Pregunta sobre cualidades */}
          <div className="form-group">
            <label>{t('question1')}</label>
            <input
              type="text"
              name="respuesta1"
              value={formData.respuesta1}
              onChange={handleChange}
              className="form-control"
              placeholder={t('response_placeholder')}
            />
          </div>

          {/* Otras preguntas con opciones */}
          <div className="form-group">
            <label>{t('question2')}</label>
            <select
              name="respuesta2"
              value={formData.respuesta2}
              onChange={handleChange}
              className="form-control"
            >
              <option value="muy_importante">{t('muy_importante')}</option>
              <option value="importante">{t('importante')}</option>
              <option value="poco_importante">{t('poco_importante')}</option>
              <option value="irrelevante">{t('irrelevante')}</option>
            </select>
          </div>

          {/* Más preguntas de selección (repetir para las demás preguntas) */}
          {/* Aquí agregamos las demás preguntas con las opciones "Es muy importante", "Es importante", etc. */}

          {/* Pregunta de país preferido */}
          <div className="form-group">
            <label>{t('questionPaisPreferido')}</label>
            <select
              name="paisPreferido"
              value={formData.paisPreferido}
              onChange={handleChange}
              className="form-control"
            >
              {/* Lista de países */}
              <option value="Ucrania">Ucrania</option>
              <option value="Georgia">Georgia</option>
              {/* Agregar los demás países aquí */}
            </select>
          </div>

          {/* Pregunta sobre comentarios */}
          <div className="form-group">
            <label>{t('questionComentario')}</label>
            <textarea
              name="comentarioMejoras"
              value={formData.comentarioMejoras}
              onChange={handleChange}
              className="form-control"
              placeholder={t('comentario_placeholder')}
            />
          </div>

          {/* Pregunta sobre recibir información */}
          <div className="form-group checkbox-group">
            <input
              type="checkbox"
              name="deseaInformacion"
              checked={formData.deseaInformacion}
              onChange={handleChange}
            />
            <label htmlFor="deseaInformacion">{t('deseaInformacion')}</label>
          </div>

          {/* Aceptación de Política de Privacidad */}
          <div className="form-group checkbox-group">
            <input
              type="checkbox"
              name="politica"
              required
              checked={formData.politica}
              onChange={handleChange}
            />
            <label htmlFor="politica">{t('politica')}</label>
          </div>

          {/* Botón de Envío */}
          <div className="form-group submit-group">
            <button type="submit" className="submit-button">
              {t('enviar')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormLibro;