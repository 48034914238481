// src/components/Header/Header.jsx

import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import axios from 'axios';
import DropDown from './DropDown';
import { TranslationContext } from '../../contexts/TranslationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,faPhone, } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import EmbajadorButton from '../Button/EmbajadoresButton';



export const languages = [
  {
    code: 'en',
    name: 'Eng',
    flag: '/images/languages/united-kingdom-uk-svgrepo-com.svg',
    hreflang: 'en-GB', // Inglés de Reino Unido
  },
  {
    code: 'de',
    name: 'Ger',
    flag: '/images/languages/germany-svgrepo-com.svg',
    hreflang: 'de-DE',
  },
  {
    code: 'en-au',
    name: 'Aus',
    flag: '/images/languages/australia-svgrepo-com.svg',
    hreflang: 'en-AU',
  },
  {
    code: 'es',
    name: 'Spa',
    flag: '/images/languages/spain-svgrepo-com.svg',
    hreflang: 'es-ES',
  },
  {
    code: 'fr',
    name: 'Fra',
    flag: '/images/languages/france-svgrepo-com.svg',
    hreflang: 'fr-FR',
  },
  {
    code: 'it',
    name: 'Ita',
    flag: '/images/languages/italy-svgrepo-com.svg',
    hreflang: 'it-IT',
  },
  {
    code: 'pt',
    name: 'Pt',
    flag: '/images/languages/flag-for-portugal-svgrepo-com.svg',
    hreflang: 'pt-PT',
  },
];


export default function Header({ logoSrc, variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [sideNav, setSideNav] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const { pathname } = useLocation();
  const { translations } = useContext(TranslationContext);

  const logoDegradadoSrc = '/images/Logotipo_Color.svg';

  const getCurrentLanguage = () => {
    const pathSegments = pathname.split('/').filter(Boolean);
    const lang = pathSegments[0];
    const supportedLanguages = languages.map((lang) => lang.code);
    return supportedLanguages.includes(lang) ? lang : 'es'; // 'es' como idioma por defecto
  };

  const currentLanguage = getCurrentLanguage();

  const isHomePage =
    pathname === '/' ||
    pathname === `/${currentLanguage}/` ||
    pathname === `/${currentLanguage}`;

  const ensureTrailingSlash = (path) => {
    if (!path) return '/';
    if (path.includes('#') || path.includes('/#')) {
      return path.replace(/\/+$/, '');
    }
    return path.endsWith('/') ? path : `${path}/`;
  };

  const sanitizeSlug = (slug) => {
    return slug ? slug.replace(/\/+$/, '') : '';
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    axios
      .get('https://dashboard.gestlifesurrogacy.com/public/api/menu')
      .then((response) => {
        const menuData = response.data;
        const filteredMenu = menuData
          .map((item) => {
            if (item.locale === currentLanguage) return item;
            const translation = item.translations.find(
              (t) => t.locale === currentLanguage
            );
            if (translation) {
              return {
                ...item,
                ...translation,
                slug: translation.slug || item.slug,
                name: translation.name || item.name,
              };
            }
            return null;
          })
          .filter(Boolean);

        setMenuItems(filteredMenu);
      })
      .catch((error) => {
        console.error('Error fetching menu items:', error);
      });
  }, [currentLanguage]);

  const buildMenuTree = (items, parentId = null) => {
    const filteredItems = items.filter((item) => item.parent_id === parentId);

    const mappedItems = filteredItems.map((item) => ({
      ...item,
      slug: sanitizeSlug(item.slug),
      children: buildMenuTree(items, item.id),
    }));

    return mappedItems;
  };

  const renderMenuItems = (items) => {
    // Ordenar los elementos según 'lft' antes de renderizarlos
    const sortedItems = [...items].sort((a, b) => a.lft - b.lft);
  
    return sortedItems.map((item) => {
      // Si el elemento no tiene slug ni link, se asigna un valor predeterminado.
      const sanitizedSlug = sanitizeSlug(item.slug || item.link || '#');
  
      // Construir el enlace base dependiendo del idioma actual.
      let basePath = '';
      if (currentLanguage !== 'es') {
        basePath = `/${sanitizedSlug}`;
      } else {
        basePath = `/${sanitizedSlug}`;
      }
  
      const itemLink = ensureTrailingSlash(basePath);
      const isAnchorLink = itemLink.includes('#');
  
      return (
        <li
          key={item.id}
          className={item.children.length > 0 ? 'menu-item-has-children' : ''}
        >
          {/* Manejar enlaces con # */}
          {isAnchorLink ? (
            <a
              href={itemLink}
              onClick={(e) => {
                e.preventDefault(); // Evita redirección al hacer clic
                setMobileToggle(false); // Cierra el menú móvil si está abierto
              }}
            >
              {item.name || 'Sin título'} {/* Evita omitir nombres vacíos */}
            </a>
          ) : (
            <Link to={itemLink} onClick={() => setMobileToggle(false)}>
              {item.name || 'Sin título'} {/* Evita omitir nombres vacíos */}
            </Link>
          )}
          {/* Renderiza los submenús, si existen */}
          {item.children.length > 0 && (
            <DropDown>
              <ul>{renderMenuItems(item.children)}</ul>
            </DropDown>
          )}
        </li>
      );
    });
  };
  

  const menuTree = buildMenuTree(menuItems);

  return (    
  <>

      <header
        className={`cs_site_header cs_style1 cs_sticky_header ${
          mobileToggle ? 'cs_mobile_toggle_active' : ''
        } ${variant} ${isSticky ? 'cs_active_sticky' : ''}`}
      >

          {/* Nueva línea encima del header */}
  <div
    className={`header-line ${isSticky ? 'sticky-line' : ''}`}
  >
    <span className="phone-number">
    <FontAwesomeIcon className="icon_header"icon={faPhone} />
    +34 935241582
    </span>
    <span className="phone-number">
    <FontAwesomeIcon className="icon_header" icon={faWhatsapp} />
    +34 935241582
    </span>
    <span className="phone-number">
    <FontAwesomeIcon className="icon_header" icon={faEnvelope} />
      info@gestlifesurrogacy.com
    </span>
   
    
  </div>


        <div className="cs_main_header">
          <div className="container">
            <div className="cs_main_header_in">
              <div className="cs_main_header_left">
                <Link
                  className="cs_site_branding"
                  to={currentLanguage !== 'es' ? `/${currentLanguage}/` : '/'}
                >
                  <img
                    src={isSticky ? logoDegradadoSrc : logoSrc}
                    alt="Logo"
                    className="site-logo"
                  />
                </Link>
                <nav className="cs_nav">
                  
                  <ul
                    className={`${
                      mobileToggle ? 'cs_nav_list cs_active' : 'cs_nav_list'
                    }`}
                  >
                    {renderMenuItems(menuTree)}

                     {/* Mostrar EmbajadorButton en el menú móvil */}
                     {mobileToggle && (
                      <li className="menu-item">
                        <EmbajadorButton className="embajador-button-mobile" />
                      </li>
                    )}
                   
                    
                  </ul>  
                 
                  

                 
                  
                  
                  <span
                    className={
                      mobileToggle
                        ? 'cs_menu_toggle cs_toggle_active'
                        : 'cs_menu_toggle'
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </nav>
              </div>

              <div className="cs_main_header_right">
                {/* EmbajadorButton solo en versión escritorio */}
                {!mobileToggle && <EmbajadorButton className="embajador-button-header" />}
                {/* Integración del LanguageSwitcher */}
                <LanguageSwitcher
                  languages={languages}
                  translations={translations}
                  currentLanguage={currentLanguage}
                />
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Resto del código permanece sin cambios */}
    </>
  );
}
